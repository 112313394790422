import React, { useState, useEffect } from "react";
import "./NavBar.css";
import vw from "../../images/redesignLogo2.jpg";
import { useNavigate } from "react-router-dom";
import { RiLoginCircleFill } from "react-icons/ri";
import { FaWhatsappSquare } from "react-icons/fa";
import { Link } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import { VscAccount } from "react-icons/vsc";

const NavBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const navigate = useNavigate();
  const handleLoginClick = () => {
    localStorage.setItem("isLoggedIn", "true");
  };

  useEffect(() => {
    const loggedIn = localStorage.getItem("isLoggedIn") === "true";
    setIsLoggedIn(loggedIn);
  }, [isLoggedIn]);

  const handleBactToHome = () => {
    navigate("/");
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleMainDataPlanPage = () => {
    navigate("/data-plan");
  };
  const handleDataPlanPage = () => {
    navigate("/data-plan");
  };
  const handleEcommercePage = () => {
    navigate("/data-plan");
  };

  const handleMyAccount = () => {
    navigate("/customer/account");
  };

  return (
    <div className='parentContainer'>
      <div className='lowerNavEle'>
        <div className='companyLogoDiv' onClick={handleBactToHome}>
          <img src={vw} alt='' />
        </div>
        <div className='navItemDiv'>
          <ul>
            <Link
              to='/data-plan'
              className='navLinkStyl'
              style={{ color: "white" }}
            >
              <li onClick={handleMainDataPlanPage}>Data</li>
            </Link>
            <Link to='/data-plan' style={{ color: "white" }}>
              <li onClick={handleEcommercePage}>Devices</li>
            </Link>
            <Link to='/data-plan' style={{ color: "white", whiteSpace: "nowrap" }}>
              <li onClick={handleDataPlanPage}>Voice Plan</li>
            </Link>
            <Link to='/data-plan' style={{ color: "white" }}>
              <li onClick={handleDataPlanPage}>Support</li>
            </Link>
            <Link to='/data-plan' style={{ color: "white", whiteSpace: "nowrap" }}>
              <li onClick={handleDataPlanPage}>SIM Service</li>
            </Link>
          </ul>
          <ul className='search'>
            {/* <li className='myAccountDiv' onClick={handleMyAccount}>
              <VscAccount className='myAccounIcon' />
              My Account
            </li> */}
            <li>
              <Link
                // to='/subscriber-login'
                className='Loginlink'
                onClick={handleLoginClick}
              >
                {isLoggedIn ? "Login" : "Logout"}
                <RiLoginCircleFill />{" "}
              </Link>
            </li>
            <li className='supportDiv'>
              <FaWhatsappSquare className='whatsappIcon' />
              <div className='supportNum'>
                <span className='justNumber'>07077011111</span> 24/7 support
              </div>
            </li>
          </ul>
        </div>
        <div className='harmburgerDiv'>
          <span onClick={toggleMenu}>
            {isMenuOpen ? <AiOutlineClose /> : <GiHamburgerMenu />}
          </span>
        </div>

        {isMenuOpen && (
          <div className='MobileNavItemDiv'>
            <ul>
              <li onClick={handleMainDataPlanPage}>Data</li>
              <li onClick={handleEcommercePage}>Devices</li>
              <li onClick={handleDataPlanPage}>Voice Plan</li>
              <li onClick={handleDataPlanPage}>Support</li>
              <li onClick={handleDataPlanPage}>SIM Service</li>
            </ul>
            <ul className='search'>
              <li>
                <Link
                  // to='/subscriber-login'
                  className='Loginlink'
                  onClick={handleLoginClick}
                >
                  {isLoggedIn ? "Logout" : "Login"}
                  <RiLoginCircleFill />{" "}
                </Link>
              </li>
              <li className='supportDiv'>
                <FaWhatsappSquare className='whatsappIcon' />
                <div className='supportNum'>
                  <span className='justNumber'>07077011111</span> 24/7 support
                </div>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default NavBar;
