import { useNavigate } from 'react-router-dom';
import Footer from '../footer';
import HomeNavComponent from '../home-nav-component';
// import "./reserve-number.css";
import './reserveNum.css';
import { FaCartPlus } from 'react-icons/fa';
import LearnMoreNav from '../learnmore-nav';
import { MdOutlineChangeCircle } from 'react-icons/md';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import useFectch from '../../utility/getFunction';
import { useDispatch } from 'react-redux';
import { addToCart, remove } from '../../redux/counter';
import { useSelector } from 'react-redux';
import { Alert } from 'react-bootstrap';
import { vitelWirelessAxios } from '../../utility/axios';

const ReserveANumber = () => {
  const [showRegular, setShowRegular] = useState(true);
  const [showPremium, setShowPremium] = useState(false);
  const [show, setShow] = useState(false);
  const [regularDefault, setRegularDefault] = useState(true);
  const [premiumDefault, setPremiumDefault] = useState(true);
  const [inputSelect, setInputSelect] = useState('');

  const [showRegularText, setShowRegularText] = useState('');
  const [checker, setChecker] = useState(false);
  const [cardId, setCardId] = useState();
  const [disable, setDisable] = useState(false);
  const [numberFilter, setNumberFilter] = useState(false);
  const [reshow, setReShow] = useState(true)
  const [productLoad, setProductLoad] = useState([])

  useEffect(() => {
    document.title = "Vitel Wireless | Reverse Number";
  }, []);

  const dispatch = useDispatch();

  const handleClose = () => setShow(false);

  const navigate = useNavigate();

  const navigateLearnMore = () => {
    navigate('/learn-more');
  };

  const navigateReservedNumber = () => {
    navigate('/reservedNumberCart');
  };

  const HandleShowRegular = () => {
    setShowRegular(true);
    setShowPremium(false);

    const targetElement = document.getElementById('premiumSection');
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const totalCount = useSelector((state) => {
    return state.cart.items.length;
  });

  const handleShow = () => {
    if (totalCount === 0) {
      setShow(true);
    } else {
      navigate('/reservedNumberCart');
    }
  };

  const handleShowPremium = () => {
    setShowPremium(true);
    setShowRegular(false);

    // Scroll down to the desired section
    const targetElement = document.getElementById('premiumSection');
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const {
    data: numbers,
    isPending: numbersIsPending,
    error: numbersError
  } = useFectch('/registrations/getUploadNumbers');

  
  const update = () => {
    vitelWirelessAxios.get("/registrations/getUploadNumbers")
        .then((res) => {
          setProductLoad(res.data);
        });
    };
    useEffect(update, [productLoad]);


  console.log("num.Primum", );

  //  Filter regular numbers
  const regularNumbers = numbers?.filter((num) => num.Premium === "No" && num.Availability === "Yes");

  // Filter premium numbers
  const PremiumNumbers = numbers?.filter((nums) => nums.Premium === "Yes" && nums.Availability === "Yes");
  const [filteredData, setFilteredData] = useState(PremiumNumbers);

  useEffect(() => {


  }, [])

  const shuffleArray = (array) => {
    let shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  const items = regularNumbers;
  const [shuffledItems, setShuffledItems] = useState(shuffleArray(items));
  const [displayedItems, setDisplayedItems] = useState(
    shuffledItems.slice(0, 6)
  );



  const reshuffle = () => {
    const newShuffledItems = shuffleArray(items);
    setShuffledItems(newShuffledItems);
    setDisplayedItems(newShuffledItems.slice(0, 6));
    setRegularDefault(false);
  };

  // Premium functions

  




  const shuffleArrayPremium = (array) => {
  
    let shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
};

  const itemsPremium = PremiumNumbers;
  const [shuffledItemsPremium, setShuffledItemsPremium] = useState(
    shuffleArrayPremium(itemsPremium)
  );
  const [displayedItemsPremium, setDisplayedItemsPremium] = useState(
    shuffledItemsPremium.slice(0, 6)
  );

  const reshufflePremium = () => {
    const newShuffledItems = shuffleArray(itemsPremium);
    setShuffledItemsPremium(newShuffledItems);
    setDisplayedItemsPremium(newShuffledItems.slice(0, 6));
    setPremiumDefault(false);
    setReShow(false)
  };

  const handleAddToCart = (num) => {
    const cartData = {
      phoneNumberId: num.phoneNumberId,
      phoneUploadId: num.phoneUploadId,
      MSISDN: num.MSISDN,
      price: num.price,
      DateAvailable: num.DateAvailable,
      status: num.status,
      Availability: num.Availability,
      Assigned: num.Assigned,
      KYC: num.KYC,
      Premium: num.Premium
    };
    setInputSelect(!inputSelect);
    dispatch(addToCart(cartData));
    setShowRegularText(cartData);

    if (cardId === num.phoneNumberId) {
      setChecker(true);
    } else {
      setChecker(false);
    }
  };

  const onchangeInput = (e) => {
    setCardId(e.target.value);
  };

  const {
    items: itemAll,
    totalAmount,
    hasError
  } = useSelector((state) => state.cart);


  const checkIds = itemAll.map((num) => num.phoneNumberId);

  // useEffect(()=>{
  //   if(){
  //     setDisable(true)
  //   }
  // },[])
  const [filteredList, setFilteredList] = useState(PremiumNumbers);
  const [selectedValue, setSelectedValue] = useState('')
  const [allPremiumDefualt, setAllPremiumDefualt] = useState('')


  // const handleSelectChange = (event) => {
  //   const value = event.target.value;
  //   setSelectedValue(value);
  //   filterList(value);
  // };




  const handleFilterChange = (event) => {
    const selectedValue = event.target.value;
    setPremiumDefault(false);
    setDisplayedItemsPremium(false)



    if (selectedValue === "11") {
      setFilteredData(PremiumNumbers.filter(item =>
        item.MSISDN.includes("11") ||
        item.MSISDN.includes("22") ||
        item.MSISDN.includes("33") ||
        item.MSISDN.includes("44") ||
        item.MSISDN.includes("55") ||
        item.MSISDN.includes("66") ||
        item.MSISDN.includes("77") ||
        item.MSISDN.includes("88") ||
        item.MSISDN.includes("99")

      ));
    } else if (selectedValue === "22") {
      setFilteredData(PremiumNumbers.filter(item =>
        item.MSISDN.includes("000")
      ));
    } else if (selectedValue === "33") {
      setFilteredData(PremiumNumbers.filter(item =>
        item.MSISDN.includes("111") ||
        item.MSISDN.includes("222") ||
        item.MSISDN.includes("333") ||
        item.MSISDN.includes("444") ||
        item.MSISDN.includes("555") ||
        item.MSISDN.includes("666") ||
        item.MSISDN.includes("777") ||
        item.MSISDN.includes("888") ||
        item.MSISDN.includes("999")
      ));

    } else if (selectedValue === "44") {
      setFilteredData(PremiumNumbers.filter(item =>
        item.MSISDN.includes("1111111") ||
        item.MSISDN.includes("2222222") ||
        item.MSISDN.includes("3333333") ||
        item.MSISDN.includes("4444444") ||
        item.MSISDN.includes("5555555") ||
        item.MSISDN.includes("6666666") ||
        item.MSISDN.includes("7777777") ||
        item.MSISDN.includes("8888888") ||
        item.MSISDN.includes("9999999") ||
        item.MSISDN.includes("0000000")
      ));

    } else {
      setFilteredData(PremiumNumbers);
    }

    fiterteredText();

  };

  const fiterteredText = (() => {
    if (filteredData) {
      setNumberFilter(true)
    } else {
      setNumberFilter(false)
    }


  })
console.log("fil", filteredData.length);

const [selectedOption, setSelectedOption] = useState("option1");

const handleOtionChange = (event)=>{

  setSelectedOption(event.target.value);
}






  const symbol = Intl.NumberFormat('en-US', {
    style: 'currency',
    currencyDisplay: 'narrowSymbol',
    currency: 'NGN'
  })
    .formatToParts(0)
    .filter(part => part.type === 'currency')
    .map(part => part.value)
    .join('')
  //  

  return (
    <>
      {/* <LearnMoreNav /> */}

      {/* <div className="first-div container">
        <div style={{ fontWeight: "bold" }}>Intereted in selling Vital wireless in your area?</div>


        <button className="button-10" role="button" onClick={navigateLearnMore}>Learn more  </button>


        <div ><FaCartPlus size={25} onClick={navigateReservedNumber} /> </div>
      </div> */}

      {/* <HomeNavComponent /> */}

      <div className=' ktReserveback'>
        <div className='backsetorder2'>
          <div className='backset2'>
            <div className='container2'>
              <h2 className='reserVHead2'>
                Reserve
                <br />
                <span className='yourNumber'>Your Number</span> Now
              </h2>
              <div>
                <p className='reserVtxt2'>
                Thank you for choosing Vitel Wireless, a new type of GSM company.
                  We promise to do things differently. We will allocate your
                  choice of your numbers and deliver the physical SIM card to
                  you very soon.
                </p>
                <div className='optionsBtnDiv'>
                 
 
                  <fieldset>
                        <legend> Would you like a premium, easy to remember Number for a fee?</legend>
                        <div class="form__group">
                          <input
                             onClick={HandleShowRegular} 
                             checked={selectedOption === "option1"}
                             name="groupt1" value="option1" id='radio1'  type='radio' 
                             className="input"
                             onChange={handleOtionChange}

                           />
                          <label for="berries_1" className="berries_2">
                          I am cool with Regular number
                          </label>
                        </div>
                        <div class="form__group">
                          <input
                            value="option2" 
                            className="input"
                          checked={selectedOption === "option2"}
                           onClick={handleShowPremium}  name="groupt12" id='radio2' type='radio'
                           onChange={handleOtionChange}
                          />
                          <label for="berries_2" className="berriespie">
                          Premium or nothing! Money is nothing
                          </label>
                        </div>
                      </fieldset>

{/*                  
                  <div className='optionsBtn'>
                    <div className='radio-itemFirst' >
                      <input onClick={HandleShowRegular} name='radio' id='radio1' checked type='radio' />
                      <label for='radio1' className='radioShowLabel'>
                     
                      </label>
                    </div>
                    <div
                      className='radio-itemFirst raio-item2'

                    >
                      <input onClick={handleShowPremium} name='radio' id='radio2' type='radio' />
                      <label for='radio2' className='radioShowLabel'>
                        Premium or nothing! Money is nothing
                      </label>
                    </div>
                  </div> */}


                </div>




                <p className='reserVtxtinteret2'>
                  If you are interested in numbers for IoT, CGU, APN for company
                  use,{" "}
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "yellowgreen",
                      cursor: "pointer",
                    }}
                  >
                    {" "}
                    click here
                  </span>
                </p>
              </div>
            </div>
            {/* <div className='heroImage'>  </div> */}
          </div>
        </div>


        <div className='mainContainerDiv ' id='premiumSection'>
          <div className='secondDivLayer'></div>
          <div className='thirdLayerDiv'>
            {showRegular && (
              <div className=' container-myForm'>

                <div className='quiz-window-title2'>
                  Pick up to 4 numbers from the options below. The numbers will
                  be reserved for you up to 30 days after our launch.{" "}
                </div>
                <div className='checkbox-wrapper-24'>
                  <div className='checkBoxDiv'>
                    <input
                      type='checkbox'
                      id='check-24'
                      name='check'
                      value=''
                    />
                    <label for='check-24' className='mshiftb'>
                      Check here, if you need more than one number
                    </label>
                  </div>
                  <div onClick={reshuffle} className='reshulfleBtn'>
                    <button className='guiz-awards-but-back'>
                      <i className='vtBackI'>
                        {" "}
                        <MdOutlineChangeCircle
                          size={25}
                          className='vtBackIcon-icon2'
                        />
                      </i>{" "}
                      Show different Sets
                    </button>
                  </div>
                </div>
                <div className='provides'>
                  {totalCount === 4 && <div> <Alert variant="success" >
                    <span>
                      You can only pick maximum of four numbers
                    </span>
                  </Alert> </div>}


                  {numbersIsPending && (

<table>
<tbody>
  <tr>
    <td className="td-1"><span></span></td>
    <td className="td-2"><span></span></td>
    <td className="td-3"><span></span></td>
    <td className="td-4"></td>
    <td className="td-5"><span></span></td>
  </tr>
  <tr>
    <td className="td-1"><span></span></td>
    <td className="td-2"><span></span></td>
    <td className="td-3"><span></span></td>
    <td className="td-4"></td>
    <td className="td-5"><span></span></td>
  </tr>
  <tr>
    <td className="td-1"><span></span></td>
    <td className="td-2"><span></span></td>
    <td className="td-3"><span></span></td>
    <td className="td-4"></td>
    <td className="td-5"><span></span></td>
  </tr>
  <tr>
    <td className="td-1"><span></span></td>
    <td className="td-2"><span></span></td>
    <td className="td-3"><span></span></td>
    <td className="td-4"></td>
    <td className="td-5"><span></span></td>
  </tr>
  <tr>
    <td className="td-1"><span></span></td>
    <td className="td-2"><span></span></td>
    <td className="td-3"><span></span></td>
    <td className="td-4"></td>
    <td className="td-5"><span></span></td>
  </tr>
  <tr>
    <td className="td-1"><span></span></td>
    <td className="td-2"><span></span></td>
    <td className="td-3"><span></span></td>
    <td className="td-4"></td>
    <td className="td-5"><span></span></td>
  </tr>
  
</tbody>
</table>
                      
                    )}

                </div>
                {/* </div> */}
                <div className='quiz-window-body'>
                  <table className='table'>
                    <thead className='thead-light'>
                      <tr>
                        <th scope='col'>Pick</th>
                        <th scope='col'>Number</th>
                        <th scope='col'>Price</th>
                        <th scope='col'>Premium</th>
                      
                      </tr>
                    </thead>

                   



                    <tbody>
                      {regularDefault &&
                        regularNumbers.slice(0, 6).map((num) => (
                          <tr>
                            <th scope='row'>
                              {checkIds?.includes(num.phoneNumberId) ? (
                                <button
                                  className='button-70Def'
                                  role='button'
                                  onChange={onchangeInput}
                                  value={num.phoneNumberId}
                                  name='check'
                                  onClick={() =>
                                    dispatch(remove(num.phoneNumberId))
                                  }
                                >
                                  Picked
                                </button>
                              ) : (
                                <button
                                  className='button-70'
                                  role='button'
                                  onChange={onchangeInput}
                                  value={num.phoneNumberId}
                                  name='check'
                                  onClick={() => handleAddToCart(num)}
                                  disabled={totalCount >= 4}
                                >
                                  Pick
                                </button>
                              )}
                            </th>
                            <td className='numDisplayBold'>{num.MSISDN}</td>
                            <td>{symbol }{num.price}</td>
                            <td> {num.Premium}</td>
                            
                          </tr>
                        ))}

                      {!regularDefault &&
                        displayedItems.map((num) => (
                          <tr>
                            <th scope='row'>
                              {checkIds?.includes(num.phoneNumberId) ? (
                                <button
                                  className='button-70Def'
                                  role='button'
                                  onChange={onchangeInput}
                                  value={num.phoneNumberId}
                                  name='check'
                                  onClick={() =>
                                    dispatch(remove(num.phoneNumberId))
                                  }
                                >
                                  Picked
                                </button>
                              ) : (
                                <button
                                  className='button-70'
                                  role='button'
                                  onChange={onchangeInput}
                                  value={num.phoneNumberId}
                                  name='check'
                                  onClick={() => handleAddToCart(num)}
                                  disabled={totalCount >= 4}
                                >
                                  Pick
                                </button>
                              )}
                            </th>
                            <td className='numDisplayBold'>{num.MSISDN}</td>
                            <td> {symbol }{num.price}</td>
                            <td> {num.Premium}</td>
                           
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>

                <div className='quiz-window-bo'>
                  <div className='guiz-awards-buttons'>
                    <div className='selectedy'>
                      Selected:{" "}
                      <span className='selectedAmount'> {totalCount}</span>{" "}
                    </div>

                    <div className='justifySubmit'>
                      <button
                        className='guiz-awards-but-back2'
                        onClick={handleShow}
                      >
                        <i className='fa fa-angle-left'></i>Click to Add
                      </button>{" "}
                    </div>
                  </div>
                </div>
              </div>
            )}




            {showPremium && (
              <div className=' container-myForm'>
                <div className='quiz-window-header'>
                  <div className='quiz-window-title2'>
                    Pick up to 4 numbers from the options below. The numbers
                    will be yours forever once you pay the premium.{" "}
                  </div>
                </div>
                <div className='checkbox-wrapper-24 '>
                  <div className='selectChoice'>
                    <label> What’s your style?</label>
                    <select className='secTech2' onChange={handleFilterChange}>
                    <option disabled>Search here...</option>
                      <option value='11'>Something Special</option>
                      <option value='22'>Lots of 0s</option>
                      <option value='33'>3somes</option>
                      <option value='44'>Lucky 7s</option>
                    </select>
                  </div>
                  <div onClick={reshufflePremium} className='reshulfleBtn'>
                    <button className='guiz-awards-but-back'>
                      <i className='vtBa'>
                        {" "}
                        <MdOutlineChangeCircle
                          size={25}
                          className='vtBackIcon-icon2'
                        />
                      </i>{" "}
                      Show different Sets
                    </button>
                  </div>
                </div>
                <div className='provides'>
                  {totalCount === 4 && <div> <Alert variant="success" >
                    <span>
                      You can only pick maximum of four numbers
                    </span>
                  </Alert> </div>}
                </div>
             
                {numbersIsPending && (

<table className='table_skeskeleton'>
<tbody>
  <tr>
    <td className="td-1"><span></span></td>
    <td className="td-2"><span></span></td>
    <td className="td-3"><span></span></td>
    <td className="td-4"></td>
    <td className="td-5"><span></span></td>
  </tr>
  <tr>
    <td className="td-1"><span></span></td>
    <td className="td-2"><span></span></td>
    <td className="td-3"><span></span></td>
    <td className="td-4"></td>
    <td className="td-5"><span></span></td>
  </tr>
  <tr>
    <td className="td-1"><span></span></td>
    <td className="td-2"><span></span></td>
    <td className="td-3"><span></span></td>
    <td className="td-4"></td>
    <td className="td-5"><span></span></td>
  </tr>
  <tr>
    <td className="td-1"><span></span></td>
    <td className="td-2"><span></span></td>
    <td className="td-3"><span></span></td>
    <td className="td-4"></td>
    <td className="td-5"><span></span></td>
  </tr>
  <tr>
    <td className="td-1"><span></span></td>
    <td className="td-2"><span></span></td>
    <td className="td-3"><span></span></td>
    <td className="td-4"></td>
    <td className="td-5"><span></span></td>
  </tr>
  <tr>
    <td className="td-1"><span></span></td>
    <td className="td-2"><span></span></td>
    <td className="td-3"><span></span></td>
    <td className="td-4"></td>
    <td className="td-5"><span></span></td>
  </tr>
  
</tbody>
</table>
                      
                    )}


                <div className='quiz-window-body'>
                  <table className='table'>
                    <thead className='thead-light'>
                      <tr>
                        <th scope='col'>Pick</th>
                        <th scope='col'>Number</th>
                        <th scope='col'>Price</th>
                        <th scope='col'>Premium</th>
                       
                      </tr>
                    </thead>

                    {
                     filteredData && filteredData.length === 0 && !premiumDefault && reshow   && <span className='datSEt-text'> The selected Category is not
                        available for the moment </span>
                    }



                    <tbody>

                      {premiumDefault ? <>
                        {PremiumNumbers && PremiumNumbers.slice(0, 6).map((num) => (
                          <tr>
                            <th scope='row'>
                              {checkIds?.includes(num.phoneNumberId) ? (
                                <button
                                  class='button-70Def'
                                  role='button'
                                  onChange={onchangeInput}
                                  value={num.phoneNumberId}
                                  name='check'
                                  onClick={() =>
                                    dispatch(remove(num.phoneNumberId))
                                  }>
                                  Picked
                                </button>
                              ) : (
                                <button
                                  className='button-70'
                                  role='button'
                                  onChange={onchangeInput}
                                  value={num.phoneNumberId}
                                  name='check'
                                  onClick={() => handleAddToCart(num)}
                                  disabled={totalCount >= 4}
                                >
                                  Pick
                                </button>
                              )}
                            </th>
                            <td className='numDisplayBold'>{num.MSISDN}</td>
                            <td> {symbol }{num.price}</td>
                            <td> {num.Premium}</td>
                           
                          </tr>
                        )

                        )}

                      </> : <>
                        {filteredData.slice(0, 6).map((num) => (
                          <tr>
                            <th scope='row'>
                              {checkIds?.includes(num.phoneNumberId) ? (
                                <button
                                  class='button-70Def'
                                  role='button'
                                  onChange={onchangeInput}
                                  value={num.phoneNumberId}
                                  name='check'
                                  onClick={() =>
                                    dispatch(remove(num.phoneNumberId))
                                  }

                                >
                                  Picked
                                </button>
                              ) : (
                                <button
                                  className='button-70'
                                  role='button'
                                  onChange={onchangeInput}
                                  value={num.phoneNumberId}
                                  name='check'
                                  onClick={() => handleAddToCart(num)}
                                  disabled={totalCount >= 4}
                                >
                                  Pick
                                </button>
                              )}
                            </th>
                            <td className='numDisplayBold'>{num.MSISDN}</td>
                            <td> {symbol }{num.price}</td>
                            <td> {num.Premium}</td>
                           
                          </tr>
                        )

                        )}
                      </>
                      }
                      {/* //////////////////////////////////////////////////////////////////// */}

                      {!premiumDefault ? <>
                        {displayedItemsPremium && displayedItemsPremium.slice(0, 6).map((num) => (
                          <tr>
                            <th scope='row'>
                              {checkIds?.includes(num.phoneNumberId) ? (
                                <button
                                  class='button-70Def'
                                  role='button'
                                  onChange={onchangeInput}
                                  value={num.phoneNumberId}
                                  name='check'
                                  onClick={() =>
                                    dispatch(remove(num.phoneNumberId))
                                  }

                                >
                                  Picked
                                </button>
                              ) : (
                                <button
                                  className='button-70'
                                  role='button'
                                  onChange={onchangeInput}
                                  value={num.phoneNumberId}
                                  name='check'
                                  onClick={() => handleAddToCart(num)}
                                  disabled={totalCount >= 4}
                                >
                                  Pick
                                </button>
                              )}
                            </th>
                            <td className='numDisplayBold'>{num.MSISDN}</td>
                            <td> {symbol }{num.price}</td>
                            <td> {num.Premium}</td>
                          
                          </tr>
                        )

                        )}

                      </> : <>
                        {filteredData.slice(0, 6).map((num) => (
                          <tr>
                            <th scope='row'>
                              {checkIds?.includes(num.phoneNumberId) ? (
                                <button
                                  class='button-70Def'
                                  role='button'
                                  onChange={onchangeInput}
                                  value={num.phoneNumberId}
                                  name='check'
                                  onClick={() =>
                                    dispatch(remove(num.phoneNumberId))
                                  }

                                >
                                  Picked
                                </button>
                              ) : (
                                <button
                                  className='button-70'
                                  role='button'
                                  onChange={onchangeInput}
                                  value={num.phoneNumberId}
                                  name='check'
                                  onClick={() => handleAddToCart(num)}
                                  disabled={totalCount >= 4}
                                >
                                  Pick
                                </button>
                              )}
                            </th>
                            <td className='numDisplayBold'>{num.MSISDN}</td>
                            <td> {symbol }{num.price}</td>
                            <td> {num.Premium}</td>
                            
                          </tr>
                        )

                        )}
                      </>
                      }



                      {/* 
                      {!premiumDefault &&
                        displayedItemsPremium &&
                        displayedItemsPremium.slice(0, 6).map((num) => (
                          <tr>
                            <th scope='row'>
                              {checkIds?.includes(num.phoneNumberId) ? (
                                <button
                                  class='button-70Def'
                                  role='button'
                                  onChange={onchangeInput}
                                  value={num.phoneNumberId}
                                  name='check'
                                  onClick={() =>
                                    dispatch(remove(num.phoneNumberId))
                                  }
                                >
                                  Picked
                                </button>
                              ) : (
                                <button
                                  class='button-70'
                                  role='button'
                                  onChange={onchangeInput}
                                  value={num.phoneNumberId}
                                  name='check'
                                  onClick={() => handleAddToCart(num)}
                                  disabled={totalCount >= 4}
                                >
                                  Pick
                                </button>
                              )}
                            </th>
                            <td className='numDisplayBold'>{num.MSISDN}</td>
                            <td> {num.Primum}</td>
                            <td>{num.price}</td>
                          </tr>
                        ))} */}


                    </tbody>

                  </table>

                  <div className='quiz-window-body-2'>
                    <div className='guiz-awards-buttons'>
                      <div className='selectedy'>
                        Selected:{" "}
                        <span className='selectedAmount'> {totalCount}</span>{" "}
                      </div>

                      <div className='justifySubmit'>
                        <button
                          className='guiz-awards-but-back2'
                          onClick={handleShow}
                        >
                          <i className='fa fa-angle-left'></i>Check Out
                        </button>{" "}
                      </div>




                    </div>
                  </div>
                </div>
              </div>
            )}

            <Modal show={show} onHide={handleClose} backdrop='static'>
              <Modal.Header>
                <Modal.Title>Vitel Number(s) Reservation</Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ fontSize: "18px" }}>
                You have not selected new numbers. Select Cancel to return to
                number selection page or{" "}
                <span className='hereModal' onClick={navigateReservedNumber}>
                  {" "}
                  click here
                </span>{" "}
                to review your numbers already selected
              </Modal.Body>
              <Modal.Footer>
                <Button variant='secondary' onClick={handleClose}>
                  Cancel
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>

      <br />
      <br />
      <br />
      <br />
      <br />
      {/* <div className='moveFooterRes'>
        <Footer />
      </div> */}
    </>
  );
};

export default ReserveANumber;
