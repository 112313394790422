import React from 'react'
import './DefaultView.css'
import { FaClipboardUser } from "react-icons/fa6";
import { FaUserCheck } from "react-icons/fa";
import { PiPhoneCallFill } from "react-icons/pi";
import activeData from '../../../../images/dataActive.png'
import foneNumber from '../../../../images/data2.png'
import userNumber from '../../../../images/data.png'
import TransactionTable from './TransactionTable';

const DefaultView = () => {
     
  return (
    <div className='parentDefault'>
      <div className='parentCardContainer'>
        <div className='userInfoCard'>
          <div className='userCardIcon'>
            <img src={userNumber} alt='' />
          </div>
          <div className='userCardTitle'>
            <span className='itemName'>MSISDN</span>
            <h2 className='dashBoardItem'>07120000021</h2>
          </div>
        </div>
        <div className='userInfoCard'>
          <div className='userCardIcon'>
            <img src={foneNumber} alt='' />
          </div>
          <div className='userCardTitle'>
            <span className='itemName'>Airtime Balance</span>
            <h2 className='dashBoardItem'> ₦3000</h2>
          </div>
        </div>
        <div className='userInfoCard'>
          <div className='userCardIcon'>
            <img src={activeData} alt='' />
          </div>
          <div className='userCardTitle'>
            <span className='itemName'>Data Balance</span>
            <h2 className='dashBoardItem'>40GB</h2>
          </div>
        </div>
      </div>
      <TransactionTable />
    </div>
  );
}

export default DefaultView