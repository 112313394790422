import React from "react";
import "./SideBar.css";
import {
  BiBookAlt,
  BiHome,
  BiMessage,
  BiSolidReport,
  BiStats,
  BiTask,
  BiHelpCircle,
} from "react-icons/bi";
import secureme from "../../../images/newLogo.png"
import { Link } from "react-router-dom";

const SideBar = ({ onMenuItemClick, activeMenuItem }) => {
  return (
    <div className='menu'>
      {/* <div className='logo'>
        <img src={secureme} alt='' />
      </div> */}
      <div className='menu-list'>
        <a
          href='#'
          className={`item ${activeMenuItem === "dashboard" ? "active" : ""}`}
          onClick={() => onMenuItemClick("dashboard")}
        >
          <BiHome className='icon' />
          Manage Account
        </a>
        <a
          href='#'
          className={`item ${
            activeMenuItem === "SubscriberOrders" ? "active" : ""
          }`}
          onClick={() => onMenuItemClick("SubscriberOrders")}
        >
          <BiTask className='icon' />
          My Order
        </a>
        <a
          href='#'
          className={`item ${
            activeMenuItem === "manageSubscribtion" ? "active" : ""
          }`}
          onClick={() => onMenuItemClick("manageSubscribtion")}
        >
          <BiSolidReport className='icon' />
          Manage Subscription
        </a>
        <a
          href='#'
          className={`item ${
            activeMenuItem === "TransactionHistory" ? "active" : ""
          }`}
          onClick={() => onMenuItemClick("TransactionHistory")}
        >
          <BiStats className='icon' />
          Transaction History
        </a>
        <a
          href='#'
          className={`item ${
            activeMenuItem === "travelPathContacts" ? "active" : ""
          }`}
          onClick={() => onMenuItemClick("travelPathContacts")}
        >
          <BiMessage className='icon' />
          My WishList
        </a>
        {/* <a
          href='#'
          className={`item ${activeMenuItem === "sosContacts" ? "active" : ""}`}
          onClick={() => onMenuItemClick("sosContacts")}
        >
          <BiHelpCircle className='icon' />
          My SOS Contact List
        </a> */}
        <a
          href='#'
          className={`item ${activeMenuItem === "report" ? "active" : ""}`}
          onClick={() => onMenuItemClick("report")}
        >
          <BiSolidReport className='icon' />
          Notification
        </a>
        <Link to='/' className='linkStyle'>
          <a href='#' className='item'>
            <BiHome className='icon' />
            Logout
          </a>
        </Link>
      </div>
    </div>
  );
};

export default SideBar;
