import React from 'react'
import './TransactionTable.css'
import airtime from "../../../../images/airtimeBonus.jpg"
import data from "../../../../images/wear/camera.jpg"
import bracelet from "../../../../images/smartbracelet.png"

const TransactionTable = () => {
  return (
    <div className='parentTransactionDiv'>
      <div className='TransactionDivTable'>
        <div className='transactionHeader'>
          <h2>Transactions</h2>
          <select>
            <option value=''>select an option</option>
            <option value='Data'>Data Purchase</option>
            <option value='CreditVoucher'>Credit voucher</option>
            <option value='Device'>Vitel Device</option>
            <option value='VAS'>Value Added Services</option>
          </select>
        </div>

        <div className='transactionListDiv'>
          <table class='table'>
            <thead>
              <tr>
                <th scope='col'>#</th>
                <th scope='col'>Product Image</th>
                <th scope='col'>Product Name</th>
                <th scope='col'>Product Price</th>
                <th scope='col'>Purchase date</th>
              </tr>
            </thead>
            <tbody class='table-group-divider'>
              <tr>
                <th scope='row'>1</th>
                <td><img src={airtime} alt='' className='productImg' /></td>
                <td><h4 className='productPurchaseName'>Air time purchase</h4></td>
                <td><span> ₦3000</span></td>
                <td><span>3/06/2024</span></td>
              </tr>
              <tr>
                <th scope='row'>2</th>
                <td><img src={data} alt=''className='productImg'  /></td>
                <td><h4 className='productPurchaseName'>Smart Camera Purchase</h4></td>
                <td><span> ₦3000</span></td>
                <td>3/06/2024</td>
              </tr>
              <tr>
                <th scope='row'>3</th>
                <td ><img src={bracelet} alt='' className='productImg' /></td>
                <td><h4 className='productPurchaseName'>Smart Bracelet purchase</h4></td>
                <td><span> ₦3000</span></td>
                <td>3/06/2024</td>
              </tr>
            </tbody>
          </table>
          {/* <div className='transMainList'>
            <div className='transactionListDet'>
              <img src={airtime} alt='' />
              <h2>Air time purchase</h2>
            </div>
            <span> ₦3000</span>
            <span>3/06/2024</span>
            <span>expires</span>
            <span>:</span>
          </div>
          <div className='transMainList'>
            <div className='transactionListDet'>
              <img src={data} alt='' />
              <h2>Smart Camera Purchase</h2>
            </div>
            <span> ₦3000</span>
            <span>3/06/2024</span>
            <span>expires</span>
            <span>:</span>
          </div>
          <div className='transMainList'>
            <div className='transactionListDet'>
              <img src={bracelet} alt='' />
              <h2>Smart Bracelet</h2>
            </div>
            <span> ₦30000</span>
            <span>3/06/2024</span>
            <span>expires</span>
            <span>:</span>
          </div>
          <div className='transMainList'>
            <div className='transactionListDet'>
              <img src={airtime} alt='' />
              <h2>Data Plan purchase</h2>
            </div>
            <span> ₦3000</span>
            <span>3/06/2024</span>
            <span>expires</span>
            <span>:</span>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default TransactionTable