import React from 'react'
import "./ProductsCart.css"
import {AiFillDelete} from 'react-icons/ai'

const ProductsCart = ({ productInCart }) => {
    console.log("productInCartparent", productInCart);
  return (
    <div className='parentCartDiv'>
      <div style={{ width: "100%" }}>
        <div className='card  '>
          <div className='card-header bg-transparent border-success'>
            Selected Product Details
          </div>

          {productInCart &&
            productInCart.map((details, i) => (
              <div className='card-body' key={i}>
                <h5 className='card-title'>{details.productName}</h5>
                <p className='card-text'>
                  <ul className='list-group'>
                    <li className='list-group-item d-flex justify-content-between align-items-center'>
                      Quantity
                      <span className=''>{details.quantity}</span>
                    </li>

                    <li className='list-group-item d-flex justify-content-between align-items-center'>
                      Remove Product
                      <span
                        className=''
                        // onClick={() =>
                        //   handleRemoveFromCart(
                        //     details.roomTypeId,
                        //     setHasErro(false)
                        //   )
                        // }
                      >
                        <AiFillDelete />
                      </span>
                    </li>

                    <li
                      className='list-group-item d-flex justify-content-between align-items-center'
                      style={{ fontSize: "0.9em" }}
                    >
                      Amount
                      <span className=''>
                        &#8358; {details.pricePerItem.toLocaleString()}
                      </span>
                    </li>

                    <li
                      className='list-group-item d-flex justify-content-between align-items-center'
                      style={{ fontSize: "0.8em" }}
                    >
                      TAX (7.5%)
                      <span className=''>
                        &#8358; {parseInt(200).toLocaleString()}
                      </span>
                    </li>
                    <li
                      className='list-group-item d-flex justify-content-between align-items-center'
                      style={{ fontSize: "1em", borderTop: "2px solid gray" }}
                    >
                      Total
                      <span className=''>
                        &#8358; {parseInt(50000).toLocaleString()}
                      </span>
                    </li>
                  </ul>
                </p>
                <div className='card-header bg-transparent border-success'></div>
              </div>
            ))}

          {/* <div className='card-header bg-transparent border-success'>
            <div className='tax-container'>
              <div className='list-group-item d-flex justify-content-between align-items-center fw-lighter fs-6 text'>
                <span>Tax 7.5%</span>
                <div className='taxAmount'>
                  <span className='taxindiviAmount'>
                    ₦ {parseInt(2000).toLocaleString()}
                  </span>
                </div>
              </div>
            </div>
          </div> */}

          <div className='card-body '>
            <p className='card-text'>
              <ul className='list-group'>
                <li className='list-group-item d-flex justify-content-between align-items-center'>
                  Ground Total
                  <span className='' style={{ fontSize: "1.5em" }}>
                    {" "}
                    &#8358; 10000
                  </span>
                </li>
              </ul>
            </p>
          </div>
          <div
            className='card-footer bg-transparent '
            style={{ border: "1px solid #2BBCF1" }}
          >
            <button
              //   disabled={totalBillOnVat === 0 ? true : false}
              className='btn btn-primary shadow-none col-md-12 '
              data-dismiss='modal'
              data-bs-toggle='modal'
              data-bs-target='#staticBackdrop'
              href='#'
            >
              Proceed To Payment
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsCart