import React from 'react'
import { IoMdNotifications } from "react-icons/io";
import { BiNotification, BiSearch } from 'react-icons/bi'
import './ContentHeader.css'

const ContentHeader = () => {
  return (
    <div className='content-header'>
      <h1 className='header-title'>My Account Management</h1>
      <div className='header-activity'>
        {/* <div className='search-box'>
          <input type='text' placeholder='search user' />
          <BiSearch className='icon' />
        </div> */}

        <div className='notify'>
          <IoMdNotifications className='icon' />
        </div>
      </div>
    </div>
  );
}

export default ContentHeader