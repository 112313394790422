import React, { useEffect, useState } from 'react'
import './ProductDetailsPage.css'
import { GrDeliver } from "react-icons/gr";
import { GiCardPickup, GiShoppingCart } from "react-icons/gi";
import SearchBar from '../EcommerceComponent/searchBar';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addToCartProduct, clearCartProduct, decrease, increase } from '../../redux/productCounter';
import { Button, Modal } from 'react-bootstrap';
import { IoIosReturnLeft } from 'react-icons/io';
import { PaystackButton } from 'react-paystack';
import { FaHome } from 'react-icons/fa';
import ProductsCart from '../ProductsCartComponent/ProductsCart';

const ProductDetailsPage = () => {
const [login, setLogin] = useState(false)

 const location = useLocation();
  const { product } = location.state || {}; 

  const dispatch = useDispatch();

  const { productItems } = useSelector((state) => state.cartProduct);

  const [referenceCode, setReferenceCode] = useState(""); 

  const[item, setItem] = useState("");
  const [showAdded, setShowAdded] = useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [modalShow, setModalShow] = useState(false);

   useEffect(() => {
     document.title = product
       ? `Vitel Wireless | Product Details - ${product.productName}`
       : "Product Details";
   }, [product]);


  const defaultEmail = "chuks1243@gmail.com";

  const handleDataProduct = ((pro)=>{
     setShowAdded(true)
    dispatch(addToCartProduct(pro))
   
    setItem(pro)
  });
  const navigate = useNavigate();
  
	const navigateCartPage = (()=>{
		navigate("/shopping-cart")
	});

  const navigateEcommerce = (()=>{
  navigate("/e-commerce")
    
  });
  const navigateLogin = (()=>{
  navigate("/subscriber-login");
    
  });

  const handlePaystackSuccessAction = (reference) => {
    setReferenceCode(reference.reference)
    dispatch(clearCartProduct())
      setModalShow(true)
     
   }
    const handlePaystackCloseAction = () => {
     navigate("/shopping-cart")
   };

   const afterPaymentNavigateECommerce = ()=>{
     navigate("/e-commerce")

   }
   const afterPaymentHome = ()=>{
    navigate("/")
   }
 

  const componentProps = {
    email: defaultEmail ,
    amount: product?.pricePerItem * 100,

    // metadata: {

    //   fullname: firstName,
    //   phone: phoneNumber,
    //   additionNumber,
    //   deliveryAddress,
    //   addtionInfo,
    //   state,
    //   city
    // },

    publicKey: "pk_test_7c1eddc66acb8a19f5d6ea061840ce18005d7ddc",
    text: 'Pay Now',
    onSuccess: (reference) => handlePaystackSuccessAction(reference),
    onClose: handlePaystackCloseAction,
  };

const itemsCount = productItems.length

console.log("logitem>>",itemsCount);
  
  const scrolltop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    scrolltop();
  }, []);

  console.log("productItems", productItems);

  const navigateShoppingCart = (()=>{
       navigate("/shopping-cart")


  })
 


  const totalPrice = productItems.reduce((acc, item) => {
      return acc + Number(item.pricePerItem) * item.quantity;
    }, 0);

    const symbol = Intl.NumberFormat('en-US', {
      style: 'currency',
      currencyDisplay: 'narrowSymbol',
      currency: 'NGN'
    })
      .formatToParts(0)
      .filter(part => part.type === 'currency')
      .map(part => part.value)
      .join('')
    //  
  return (
    <div className='parentProductDetailsPage'>
      <SearchBar />

      <div className='bredMove'>
      <nav className="breadcrumbs">
     <a  className="breadcrumbs__item">Home</a>
     <a className="breadcrumbs__item">Shop</a> 
      <a className="breadcrumbs__item">Cart</a>
      <a className="breadcrumbs__item is-active">Checkout</a> 
      </nav>
      </div>
      
      {
                    showAdded  &&

          <div className="imgMOdalDiv">
            <div className='dtailDiv1'>
              <div className='dtailDiv1-inside'>
              <div> <img className="imgMOdal" src={item?.displayImage}/>   </div>
            <div> <span className="itName">{item?.productName}  </span>
            <br/>
             has been added to your cart</div>
            </div>
              </div>
            
            <div className='dtailDiv2'>
              <div className='hmCSubtotal'>Cart Subtotal: {symbol}{totalPrice}</div>
              <div><button className="button-Upper" role="button" >Proceed to checkout ({itemsCount} items)</button></div>
              <p></p>
             <div><button className="button-13" role="button" onClick={navigateCartPage} >Go to Cart</button></div>
             
            </div>
          
         
            </div>
}


      <div className='productDetailsPage'>


    


        <div className='productDeilsImgDiv'>
          <div className='productDetIMG'>
            <img src={product?.displayImage} alt='' />
          </div>
          <div className='productOtherIMG'>
            <div className='image1'>
              <img src={product?.displayImage} alt='' />
            </div>
            <div className='image1'>
              <img src={product?.displayImage} alt='' />
            </div>
            <div className='image1'>
              <img src={product?.displayImage} alt='' />
            </div>
          </div>
        </div>
        <div className='productDetailsDescripDiv'>
          <div className='productdetName'>
            <div className='productDetHeadPart'>
              <div>
                <h3>{product?.productName}</h3>
                <span className='productNameSpan'>
                  {" "}
                  N{product?.pricePerItem}
                </span>
              </div>
              <div className='buyItemDivProductDes'>
                <button
                  className='planAddTOCart2'
                  onClick={() => handleDataProduct(product)}
                >
                  Add to cart
                </button>

                {login ? (
                  <PaystackButton {...componentProps} className='planBuyNow2' />
                ) : (
                  <button className='planBuyNow2' onClick={navigateLogin}>
                    Pay Now
                  </button>
                )}
              </div>
            </div>

            <p>{product?.longDescription}</p>
            <h4>Short Description</h4>
            <div className='promotionalOffer'>{product?.shortDescription}</div>
            <div className='modelNameInfoDiv'>
              <span>Sub Category Name</span>
              <div className='modelNameInfo'>{product?.subCategoryName}</div>
            </div>
            <div className='modelNameInfoDiv'>
              <span>Product Status</span>
              <div className='modelNameInfo'>{product?.productStatus}</div>
            </div>
            <div className='modelNameInfoDiv'>
              <span>Product Type</span>
              <div className='modelNameInfo'>{product?.productType}</div>
            </div>
            <div className='quantityDiv'>
              <div className='quantityLabel'>Current Discount</div>
              <div className='quantityBtnDiv'>
                {/* <button className='decreaseBtn' onClick={()=>  dispatch(decrease(product.productId))} disabled={product.quantity === 1}>-</button> */}
                <span> {product?.currentDiscount} </span>
                {/* <button className='increaseBtn' onClick={()=>  dispatch(increase(product.productId))}>+</button> */}
              </div>
            </div>
          </div>
        </div>
        <div className='productDeliveryDiv'>
          <ProductsCart productInCart={productItems} />
          {/* <div className='inStockDiv'>
            <span>IN STOCK</span>
          </div> */}
          {/* <div class='card  mb-3'>
            <div class='card-header bg-transparent  deliveryIconDiv'>
              <GrDeliver className='deliveryIcon' /> Delivery
            </div>
            <div class='card-body '>
              <h5 class='card-title'>Vitel Wireless</h5>
              <p className='card-text cardText'>
                Estimated delivery: 2 to 3 days depending on your location.
              </p>
            </div>
            <div class='card-footer bg-transparent '>
              <div class='card border-success mb-3' >
              <div class='card-header bg-transparent border-success'>
                  Header
                </div>
              <div class='card-body text-success'>
              <h5 class='card-title'>Delivery Company</h5>
              <p class='card-text cardText'>
                Estimated delivery: 2 to 3 days depending on your location
              </p>
              </div>
              <div class='card-footer bg-transparent border-success'> 
                  Footer
                </div>
              </div>
            </div>
          </div> */}
          {/* <div class='card  mb-3'>
            <div class='card-header bg-transparent  deliveryIconDiv'>
              {" "}
              <GiCardPickup className='deliveryIcon' /> Pick Up
            </div>
            <div class='card-body'>
              <h5 class='card-title'>Pick up time</h5>
              <p class='card-text cardText'>
                Available for pick up after 48 hours
              </p>
            </div>
            <hr />
            <div class='card-body'>
              <h5 class='card-title'>Return policy</h5>
              <p class='card-text cardText'>
                Free return within 7 days for ALL eligible items
              </p>
            </div>
          </div> */}
        </div>
      </div>

    

      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className='proDisplayforcart'>
            Product successfully added to your cart
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='imgMOdalDiv'>
            <div className='imtDiv'>
              <div>
                {" "}
                <img className='imgMOdal' src={item?.displayImage} />{" "}
              </div>
            </div>

            <div>
              {" "}
              <span className='itName'>{item?.productName} </span> has been
              added to your cart
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={navigateEcommerce}>
            <IoIosReturnLeft /> Continue shopping
          </Button>
          <Button className='aCartMpro' onClick={navigateCartPage}>
            <GiShoppingCart /> Check cart
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size='lg'
        // aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
      >
        <Modal.Header>
          <Modal.Title
            id='contained-modal-title-vcenter'
            style={{ color: "green" }}
          >
            Order Completed Succcesfully
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4></h4>
          <p>
            You have completed your order, you can purchase more durable and
            affordable products from us,
          </p>
          <p>Thanks for your patronage, your goods will be delivered soon!</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={navigateEcommerce}>
            <IoIosReturnLeft /> Continue shopping
          </Button>
          <Button onClick={afterPaymentHome} className='aCartMpro'>
            <FaHome /> Go Home
          </Button>
        </Modal.Footer>
      </Modal>

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}

export default ProductDetailsPage