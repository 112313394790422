import React, { useEffect } from "react";
import "./Content.css";
import ContentHeader from "../ContentHeaderComponent/ContentHeader";
import Reports from "../ReportsComponents/Reports";
import SubscriberProfile from "../SubscriberProfile/SubscriberProfile";
import SubscriberOrders from "../SubscriberOders/SubscriberOrders";
import DefaultView from "../DefaultViewComponent/DefaultView";
import TransactionHistory from "../TransactionHistory/TransactionHistory";
import ManageSubscription from "../ManageSubscription/ManageSubscription";

const Content = ({ activeComponent }) => {
  console.log("onMenuItemClick", activeComponent);
  const renderComponent = () => {
    switch (activeComponent) {
      case "SubscriberProfile":
        return <SubscriberProfile />;
      case "manageSubscribtion":
        return <ManageSubscription />;
      case "TransactionHistory":
        return <TransactionHistory />;
      case "SubscriberOrders":
        return <SubscriberOrders />;
      case "report":
        return <Reports />;
      // Add more cases for other components
      default:
        return <DefaultView />; // Default component (dashboard or any other default view)
    }
  };

  return (
    <div className='content'>
      <ContentHeader />
      {renderComponent()}
    </div>
  );
};

export default Content;
