import React from 'react'
import "./SubscriberProfile.css";
import { MdDelete } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import { MdOutlinePhoneLocked } from "react-icons/md";

const SubscriberProfile = () => {
    const sosList = [
      {
        name: "chidi joel",
        icon: <MdDelete />,
        sos: true,
      },
      {
        name: "paul john",
        icon: <MdDelete />,
        sos: true,
      },
      {
        name: "emma maxicov",
        icon: <MdDelete />,
        sos: true,
      },
      {
        name: "Abel doe",
        icon: <MdDelete />,
        sos: true,
      },       
    ];  
  return (
    <div className='parentSubscriberprofile'>
      <div className='profileheaderPart'>
        <h2 className='header-title'>Profile</h2>
        <div className='profileEditDiv'>
          <FaRegEdit className='profileEditIcon' />
        </div>
      </div>

      <div className='userProfileInfo'>
        <div className='userDetails'>
          <img
            src='https://i.pinimg.com/474x/0a/a8/58/0aa8581c2cb0aa948d63ce3ddad90c81.jpg'
            alt=''
          />
          <h3 className='subscriberName'>John Doe</h3>
          <span className='subscriberEmail'>anigbo.joel@gmail.com</span>
        </div>

        <div className='linkedNumber'>
          <div className='numbersDiv'>
            <div className='number'>
              <div className='numberCover'>
                <MdOutlinePhoneLocked />
              </div>
              <div className='mainNumberDiv'>
                <span className='actualNum'>90937848494</span>
                <span className='activeNum'>Active</span>
              </div>
            </div>
            <div className='action'>:</div>
          </div>
          <div className='numbersDiv'>
            <div className='number'>
              <div className='numberCover'>
                <MdOutlinePhoneLocked />
              </div>
              <div className='mainNumberDiv'>
                <span className='actualNum'>90937848494</span>
                <span className='activeNum'>Active</span>
              </div>
            </div>
            <div className='action'>:</div>
          </div>
          <div className='numbersDiv'>
            <div className='number'>
              <div className='numberCover'>
                <MdOutlinePhoneLocked />
              </div>
              <div className='mainNumberDiv'>
                <span className='actualNum'>90937848494</span>
                <span className='activeNum'>Active</span>
              </div>
            </div>
            <div className='action'>:</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubscriberProfile;