import React,{useEffect} from 'react'
import done from "../../../images/done.gif"
import './AirTimeTopSuccess.css'
import { useLocation, useNavigate } from "react-router-dom";
import { IoMdShare } from "react-icons/io";
import { GiSaveArrow } from "react-icons/gi";
import SearchBar from '../../EcommerceComponent/searchBar';

const AirtimeTopUpSuccess = () => {
    const location = useLocation()
    const navigation = useNavigate()

    const { amount, MSISDN, subscriberInfo, paystackPaymentReference } = location.state || {};
    
	 useEffect(() => {
     document.title = amount
       ? `Vitel Wireless | purchase confirmation`
       : "purchase confirmation";
   }, [amount]);

    const handleBackToHome = () =>{
        navigation("/");
    }

    const handleShareReceipt = () => {
      if (navigator.share) {
        navigator
          .share({
            title: "Airtime Top-up Receipt",
            text: `Your airtime top-up of ${amount} to ${MSISDN} was successful. Reference number: ${paystackPaymentReference}.`,
            url: window.location.href, // You can add a relevant URL here
          })
          .then(() => console.log("Receipt shared successfully"))
          .catch((error) => console.error("Error sharing receipt:", error));
      } else {
        alert("Sharing is not supported in this browser.");
      }
    };
  
  return (
    <div className='parentAirtimetopsuccess'>
      <SearchBar />
      <div className='book_form_room_list'>
        <div className='book_hotel__details'>
          <div className='book_hotel_img'>
            <img src={done} alt='hotel' />
          </div>
        </div>

        <div className='book_hotel__details2'>
          <h4>
            Thank your for shopping with <br />
            vitel wireless
          </h4>
        </div>

        <div className='book_view_more'>
          <span className='spanDis'>
            <span>MSISDN: </span>
            <span>{MSISDN}</span>{" "}
          </span>
        </div>

        <div className='book_view_more_with_tax'>
          <span className='spanDis'>
            <span>Name on MSISDN</span>{" "}
            <span>
              {/* {subscriberInfo?.lastName} {subscriberInfo?.firstName} */}
            </span>{" "}
          </span>
        </div>

        <div className='book_view_more_with_tax'>
          <span className='spanDis'>
            <span>Recharge Amount</span>
            {/* <span>{amount}</span>{" "} */}
          </span>
        </div>

        <div className='book_view_more_with_tax subTotal'>
          <span className='spanDis'>
            <span>Order reference number</span>{" "}
            {/* <span>{paystackPaymentReference}</span>{" "} */}
          </span>
        </div>

        <div className='book_view_more_with_tax subTotal deliveryInfo'>
          <span>Your Delivery/PickUp information</span>
          <p>

          </p>
        </div>

        <div className='backToHome'>
          <div className='saveIconDiv'>
            <span className='shareIconspan'>
              <GiSaveArrow className='saveIcon' />
            </span>
            <span>Download Receipt</span>
          </div>
          <div
            className='shareIconDiv saveIconDiv2'
            onClick={handleShareReceipt}
          >
            <span className='shareIconspan'>
              <IoMdShare className='saveIcon' />
            </span>
            <span>Share Receipt</span>
          </div>

          <button className='getStartedBtn' onClick={handleBackToHome}>
            Back To Home
          </button>
        </div>
      </div>
    </div>
  );
}

export default AirtimeTopUpSuccess