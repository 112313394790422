import "./shoppingCart.css";
import image from "../../images/wear/watch2.jpg";
import { AiOutlineMinus } from "react-icons/ai";
import { BsPlus } from "react-icons/bs";
import Modal from "react-bootstrap/Modal";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  clearCart,
  clearCartProduct,
  increase,
  removeProduct,
  decrease,
} from "../../redux/productCounter";
import { clear } from "@testing-library/user-event/dist/clear";
import cartImage from "../../images/SHOP.png";
import { PaystackButton } from "react-paystack";
import { FaHome } from "react-icons/fa";
import { IoIosReturnLeft } from "react-icons/io";
import SearchBar from "./searchBar";

const ShoppingCart = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [referenceCode, setReferenceCode] = useState("");
  const [modalShow, setModalShow] = useState(false);

  const dispatch = useDispatch();

  const scrolltop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };



  useEffect(() => {
    document.title = "Vitel Wireless | Shopping cart";
    scrolltop();
  }, []);

  const afterPaymentNavigateECommerce = () => {
    navigate("/e-commerce");
  };
  const afterPaymentHome = () => {
    navigate("/");
  };

  const navigate = useNavigate();

  const navigateEcommerce = () => {
    navigate("/e-commerce");
  };

  const { productItems } = useSelector((state) => state.cartProduct);

  const totalPrice = productItems.reduce((acc, item) => {
    return acc + Number(item.pricePerItem) * item.quantity;
  }, 0);

  // const productItems1 = useSelector((state)=>state.cartProduct)

  console.log(" productItems>>>>>>", productItems);

  const defaultEmail = "chuks1243@gmail.com";

  const symbol = Intl.NumberFormat("en-US", {
    style: "currency",
    currencyDisplay: "narrowSymbol",
    currency: "NGN",
  })
    .formatToParts(0)
    .filter((part) => part.type === "currency")
    .map((part) => part.value)
    .join("");
  //

  const handlePaystackSuccessAction = (reference) => {
    setReferenceCode(reference.reference);
    dispatch(clearCartProduct());
    setModalShow(true);
  };
  const handlePaystackCloseAction = () => {
    navigate("/shopping-cart");
  };

  const componentProps = {
    email: defaultEmail,
    amount: totalPrice * 100,

    // metadata: {

    //   fullname: firstName,
    //   phone: phoneNumber,
    //   additionNumber,
    //   deliveryAddress,
    //   addtionInfo,
    //   state,
    //   city
    // },

    publicKey: "pk_test_7c1eddc66acb8a19f5d6ea061840ce18005d7ddc",
    text: "PAY NOW",
    onSuccess: (reference) => handlePaystackSuccessAction(reference),
    onClose: handlePaystackCloseAction,
  };

  return (
    <>
      <SearchBar />
      <div className=' container-sp'>
        {productItems?.length === 0 ? (
          <div className=' emptyCartDIv'>
            <div>
              {" "}
              <img src={cartImage} className='image-Empty' />{" "}
            </div>
            <h2>NO Product in Your Cart</h2>

            <button
              className='button-65'
              role='button'
              onClick={navigateEcommerce}
            >
              Go Shopping
            </button>

            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
        ) : (
          <div className='wrapp wrapper-contenter animated fadeInRight'>
            <div className='row'>
              <div className='col-md-9 inner-cartstyle'>
                <div className='ibox'>
                  <div className='ibox-title'>
                    <span className='pull-right'>
                      (<strong> 1 </strong>) items
                    </span>
                    <h5>Items in your cart</h5>
                  </div>

                  {productItems.map((product) => (
                    <div className='ibox-content'>
                      <div className='table-responsive'>
                        <table className='table shoping-cart-table'>
                          <tbody>
                            <tr className='tr-container-product'>
                              <div>
                                <img
                                  className='cart-product-imitation'
                                  src={product.displayImage}
                                />
                              </div>
                              <div className='desc'>
                                <h2>{product.productName} </h2>
                                <div className='descriptioncover'>
                                  <h5>Description </h5>
                                  <div className='spanLINE-cart'></div>
                                  <dd className='description-Text'>
                                    {product.shortDescription}
                                  </dd>
                                </div>
                                <td className='price-back'>
                                  <span>
                                    Price:{" "}
                                    <span className='boldtesting'>
                                      {" "}
                                      {symbol}
                                      {product.pricePerItem}{" "}
                                    </span>
                                  </span>
                                </td>

                                <div className='controlcounter'>
                                  <button
                                    className='tractminus'
                                    onClick={() =>
                                      dispatch(decrease(product.productId))
                                    }
                                    disabled={product.quantity === 1}
                                  >
                                    <i>
                                      <AiOutlineMinus size={30} />
                                    </i>
                                  </button>
                                  <div className='incrementnum'>
                                    {" "}
                                    <span> {product.quantity}</span>
                                  </div>
                                  <button
                                    className='trackplus'
                                    onClick={() =>
                                      dispatch(increase(product.productId))
                                    }
                                  >
                                    {" "}
                                    <i>
                                      <BsPlus size={30} />
                                    </i>
                                  </button>
                                </div>

                                <div className='AlertRed-quantity'></div>
                                <div className='m-t-sm removeDiv'>
                                  <a
                                    className='text-muted'
                                    onClick={() =>
                                      dispatch(removeProduct(product.productId))
                                    }
                                  >
                                    <i className='fa fa-trash'></i> Remove{" "}
                                    <span className='item-itemRemove'>
                                      item
                                    </span>
                                  </a>
                                </div>
                                <hr />
                              </div>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ))}

                  <div className='ibox-content'>
                    <button
                      className='btn btn-white continuebtn-shopping'
                      onClick={navigateEcommerce}
                    >
                      <i className='fa fa-arrow-left'></i> Continue shopping
                    </button>
                    <button
                      className='button-24'
                      role='button'
                      onClick={() => dispatch(clearCartProduct())}
                    >
                      Clear Cart
                    </button>
                  </div>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='ibox'>
                  <div className='ibox-title'>
                    <h5>Cart Summary</h5>
                  </div>
                  <div className='ibox-conten-check'>
                    <span className='totalspan'>Total Amount</span>
                    <h2 className='font-bold'>
                      {symbol}
                      {totalPrice}
                    </h2>

                    <hr />

                    <div className='m-t-sm'>
                      <button className='button-6' role='button'>
                        <PaystackButton
                          {...componentProps}
                          className='button-6'
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>
            <label>Payment</label>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='setFOrm-modal'>
          {/* <label>Email address</label>
        <input className="addRESs-input"/> */}
          Proceed to payment
        </Modal.Body>

        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
          <Button variant='primary' onClick={handleClose}>
            Pay Now
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        show={modalShow}
      >
        <Modal.Header>
          <Modal.Title
            id='contained-modal-title-vcenter'
            style={{ color: "green" }}
          >
            Order Completed Succcesfully
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4></h4>
          <p>
            You have completed your order, you can purchase more durable and
            affordable products from us,
          </p>
          <p>Thanks for your patronage, your goods will be delivered soon!</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={navigateEcommerce}>
            <IoIosReturnLeft /> Continue shopping
          </Button>
          <Button onClick={afterPaymentHome} className='aCartMpro'>
            <FaHome /> Go Home
          </Button>
        </Modal.Footer>
      </Modal>

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </>
  );
};
export default ShoppingCart;
