import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom';
import Home from './components/home';
import Faq from './components/layouts/faq';
import ReserveANumber from './components/layouts/reserve-number/reserve-a-number';
import LearnMore from './components/layouts/learnmore';
import ReservedNumberCart from './components/layouts/reserve-number/reserved-number-cart';
import HomePage from './components/HomePageComponents/HomePage';
import Footer from './components/layouts/FooterComponent/footer';
import Login from './components/layouts/Login/Login';
import NavBar from './components/layouts/NavBarComponent/NavBar';
import Devices from './components/layouts/DeviceSection/Devices';
import DataPage from './components/layouts/DataComponent/DataPage';
import BottomMenu from './components/layouts/bottomMenuComponent/bottomMenu';
import PlanPurchase from './components/layouts/PlanPurchase/PlanPurchase';
import Ecommerce from './components/layouts/EcommerceComponent/e-commerce';
import ShoppingCart from './components/layouts/EcommerceComponent/shoppingCart';
import ProductDetailsPage from './components/layouts/ProductDetailsPage/ProductDetailsPage';
import MyAccount from './components/layouts/MyAccount/MyAccount';
import DashBoard from "./components/layouts/SubscriberDashboard/DashBoard";
import AirTimeTopConfirm from './components/layouts/AirTimeTopConfirm/AirTimeTopConfirm';
import AirtimeTopUpSuccess from './components/layouts/AirTimeTopConfirm/AirTimeTopUpSuccess/AirtimeTopUpSuccess';
import DataPlans from './components/layouts/DataPlans/DataPlans';
import SocialMediaBundlesPage from './components/layouts/SocialMediaBundles/SocialMediaBundlesPage/SocialMediaBundlesPage';
import EcomCategories from './components/layouts/EcommerceComponent/categories';
import Page404 from './components/layouts/404Page/Page404';

function App() {
  return (
    <>
      <Router>
        <NavBar />
        <Routes basename='/'>
          <Route path='/' element={<HomePage />} />
          <Route
            path='/plan-purchase/:id/:productName'
            element={<PlanPurchase />}
          />
          <Route
            path='/product-details/:id/:productName'
            element={<ProductDetailsPage />}
          />
          <Route path='/air-time-top-up' element={<AirTimeTopConfirm />} />
          <Route path='/data/data-plans' element={<DataPlans />} />
          <Route
            path='/data/social-media-bundles'
            element={<SocialMediaBundlesPage />}
          />
          <Route
            path='/air-time-top-confirm'
            element={<AirtimeTopUpSuccess />}
          />
          <Route path='/customer/account' element={<DashBoard />} />
          <Route path='/faq' element={<Faq />} />
          <Route path='/reserve-number' element={<ReserveANumber />} />
          <Route path='/learn-more' element={<LearnMore />} />
          <Route path='/reservedNumberCart' element={<ReservedNumberCart />} />
          <Route path='/devices' element={<Devices />} />
          <Route path='/data-plan' element={<DataPage />} />
          <Route path='/subscriber-login' element={<Login />} />
          <Route path='/footer-menu' element={<BottomMenu />} />
          {/* <Route path='/e-commerce' element={<Ecommerce />} /> */}
          <Route path='/shopping-cart' element={<ShoppingCart />} />
          <Route path='/categories' element={<EcomCategories />} />
          <Route path='*' element={<Page404 />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
