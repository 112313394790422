import React from 'react'
import './Page404.css'
import img404 from '../../images/404IMG.gif'
import { Link } from 'react-router-dom';
import { BiHomeSmile } from "react-icons/bi";
const Page404 = () => {
  return (
    <div className='parent404'>
      <div className='MainDiv404'>
        <div className='404imagDiv'>
          <img src={img404} alt='' />
        </div>
        <h3 className='error404'>Oops Error!!!</h3>
        <h4>The page you're looking for doesn't exist.</h4>
        <Link to='/' className='backHome'><BiHomeSmile className='homeIcon'/>Back to Home</Link>
      </div>
    </div>
  );
}

export default Page404