import React, { useState, useEffect } from "react";
import "./SubscriberUtility.css";
import { vitelWirelessAxios } from "../../utility/axios";
import noItemFound from "../../images/noproduct.png"
import { useNavigate } from "react-router-dom";
import secureme from "../../images/secureme.jpg";
import ogaApp from "../../images/ogaapp.jpg";
import ogaBoiz from "../../images/ogaboiz.jpg";
import { Link } from "react-router-dom";

const SubscriberUtilities = () => {
  const [VitelVASProduct, setVitelVasProduct] = useState([]);
  const [VitelVASProductCategory, setVitelVASProductCategory] = useState([]);
  // const [selectedCategory, setSelectedCategory] = useState("");
  const [checkEmptyArr, setCheckEmptyArr] = useState(false)
   const navigation = useNavigate()

     const activateVAS = () => {
      navigation("/product-details");
     }

     const handleNavigateDetails = ((devices)=>{
       
      //  navigation(`/product-details/id=${devices.productId}/name=${devices.productName}`, { state: { product: devices } });
   
     })

  const handleCategoryChange = async (e) => {
    const selectedCategory = e.target.value;
    const productDetails = await vitelWirelessAxios.get(`/generals/getAllPublicImages`);

    if (selectedCategory == "all") {
      const filteredProductServOnly = productDetails.data.filter(
        (service) => service.categoryName === "Premium/Value Added Services"
      );
      const subcategoryArray = filteredProductServOnly.flatMap((item) => item.subCategoryImages);
      setCheckEmptyArr(false);
      setVitelVasProduct(subcategoryArray);
    } else {
      const filteredServices = productDetails.data.filter(
        (service) => service.productSubCategory == selectedCategory);
        
      if (filteredServices.length === 0){
        setCheckEmptyArr(true)
      } else{
        setCheckEmptyArr(false)
      }
      setVitelVasProduct(filteredServices[0]?.subCategoryImages);
    }
  };

  useEffect(() => {
    const getVitelVASProduct = async () => {
      const vasProductDetails = await vitelWirelessAxios.get(
        `/generals/getAllPublicImages`
      );
      const vasProductCategory = await vitelWirelessAxios.get(
        `/generals/getAllCategories `
      );

      const filteredProductServCategory = vasProductCategory.data.filter(
        (service) => service.categoryName === "Premium/Value Added Services"
      );

      const filteredProductServOnly = vasProductDetails.data.filter(
        (service) => service.categoryName === "Premium/Value Added Services"
      );

       const subcategoryArray = filteredProductServOnly.flatMap((item) => item.subCategoryImages);

       console.log("subcategoryArray", subcategoryArray);

      if (filteredProductServOnly.length === 0) {
        setCheckEmptyArr(true);
      } else {
        setCheckEmptyArr(false);
      }
      setVitelVASProductCategory(filteredProductServCategory[0].subCategories);
      setVitelVasProduct(subcategoryArray);
    };
    getVitelVASProduct();
  }, []);



  return (
    <div className='parentAppListing'>
      <div className='getStartedContainer'>
        <div className='getStartedContainerItem'>
          <span className='getSecured'>
            Get Secured <hr />
          </span>
          <div className='filterDiv'>
            <label htmlFor='vasFilter'>filter VAS</label>
            <select name='vasFilter' id='' onChange={handleCategoryChange}>
              <option value='' disabled>Select an option</option>
              <option value="all">All</option>
              {VitelVASProductCategory &&
                VitelVASProductCategory.map((category) => (
                  <option
                    key={category.subCategoryId}
                    value={category.subCategoryId}
                  >
                    {category.subCategoryName}
                  </option>
                ))}
            </select>
          </div>
        </div>
      </div>
      <div className='parentAppSampleDiv'>
        <div className='appSampleDiv'>
          {checkEmptyArr ? (
            <div className='secureMe2'>
              <img src={noItemFound} alt='' />
            </div>
          ) : (
            VitelVASProduct && VitelVASProduct?.map((vasProduct) => (
              <Link to="/" onClick={()=>handleNavigateDetails(vasProduct)} className='secureMe' >
                <div className='vasType'>Free</div>
                <div className='vasParentPriceDiv'>
                  <div className='vasPrice'>
                    &#8358; {parseInt(vasProduct.pricePerItem).toLocaleString()}
                  </div>
                </div>
                <div className='secureMe-header'>
                  <img src={vasProduct.displayImage} alt='' />
                </div>
                <div className='appBody'>
                  <p className='description'>{vasProduct.shortDescription}</p>
                </div>
              </Link>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default SubscriberUtilities;
