import React, {useEffect} from 'react'
import Hero from '../layouts/HeroSection/Hero';
import UserLineDetails from '../layouts/UserDetailsComponent/UserLineDetails';
import SubscriberUtilities from '../layouts/SubcribersUtilities/SubscriberUtilities';
import VitelFeatures from '../layouts/VitelFeatures/VitelFeatures';
import QuickLink from '../layouts/QuickLink/QuickLink';
import Footer from '../layouts/footer';
import Devices from '../layouts/DeviceSection/Devices';
import IoTDevices from '../layouts/IoTDeviceComponent/IoTDevices';
import VitelServicePlan from '../layouts/VitelServicePlan/VitelServicePlan';
import TopUp from '../layouts/TopUpComponent/TopUp';
import SocialMediaBundlesLink from '../layouts/SocialMediaBundles/SocialMediaBundlesLink';

// import Hero from '../HeroSection/Hero';
// import SubscriberUtilities from '../AppContainerComponent/SubscriberUtilities';
// import VitelFeatures from '../AppContainerComponent/VitelFeatures/VitelFeatures';
// import QuickLink from '../AppContainerComponent/QuickLink/QuickLink';
// import UserLineDetails from '../AppContainerComponent/UserDetailsComponent/UserLineDetails';

const HomePage = () => {
   useEffect(() => {
     document.title = "Vitel Wireless Limited | Home";
   }, []);
  return (
    <div>
      <Hero />
      <TopUp />
      {/* <UserLineDetails /> */}
      <VitelServicePlan />

      <SubscriberUtilities />
      <VitelFeatures />
      <IoTDevices />

      <Devices />
      {/* <SocialMediaBundlesLink /> */}
      {/* <QuickLink /> */}
      {/* <Footer /> */}
    </div>
  );
}

export default HomePage