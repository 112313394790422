import React from 'react'
import './Devices.css'
import virtualReality from "../../images/vitelVirtual.jpg"
import braceLet from "../../images/smartbracelet2.png"
import girlDevice from "../../images/girlDevice.png"
import { Link, useNavigate } from 'react-router-dom'

const Devices = () => {


const navigate = useNavigate();

const navigateEcommerce = (()=>{

  // navigate("/e-commerce")
})


  return (
    <div className='parentDeviceSection'>
      <div className='deviceSectionContainer'>
        <div className='deviceSectionHeader'>
          <span className='deviceHeaderTitle'>Devices</span>
          <Link to='/' className='deviceButton'>
            <button 
            onClick={navigateEcommerce}
            >See All Devices</button>
          </Link>
        </div>
        <div className='mainDevicesList'>
          <div className='virtual'>
            <img src={virtualReality} alt='' />
          </div>
          <div className='bracelet'>
            <img src={braceLet} alt='' />
          </div>
          <div className='braceletSecond'>
            <img src={girlDevice} alt='' />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Devices