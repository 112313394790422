import React,{useEffect} from 'react'
import './DataPage.css'
import dataImage from "../../images/voiceCallImage.png"
import smsImage from "../../images/smsTextimage.png"
import data from "../../images/DATA BANA.png"
import UserLineDetails from '../UserDetailsComponent/UserLineDetails'
import Faq from '../faq'
import VitelFeatures from '../VitelFeatures/VitelFeatures'    

const DataPage = () => {    
   useEffect(() => {
     document.title =  `Vitel Wireless | Blue is Coming `
   }, []);
  return (
    <>
      <div className='parentDataPage'>
        <div className='parentDataHero'>

        </div>
        <div className='dataBlueIsComingParent'>

        </div>

      </div>

      <div className='dataTextCEnter'>


        <div className='contain callingDIv'>

          <div>
            <img src={dataImage} className='side-imageS' />
          </div>

          <div className='dataText-side' >
            <h3 className='cal'>Calling/Voice & SMS Plans:</h3>
            <div className='line-textSide'></div>
            <br />

            
            Blue is coming with a different way of doing business with Nigerians. Our goal is to put you back into the driver’s
            seat, putting you in charge and letting you control what, where, and how you spend your voice (phone/SMS) credits.
            No gimmicks.



          </div>
        </div>

        <div className='contain callingDIv'>


          <div className='dataText-side' >
            <h3 className='cal'>No packages</h3>
            <div className='line-textSide'></div>
            <br />
            Voice is charged per second.
            We will stick to per second billing to offer you the lowest price 
            possible. We will show you the details of all your calls, 
            seconds spent, and cost charged on each call.



          </div>

          <div>
            <img src={smsImage} className='side-imageS' />
          </div>
        </div>

        <div className='contain callingDIv'>


          <div>
            <img src={data} className='side-imageS' />
          </div>
          <div className='dataText-side' >
            <h3 className='cal'>No gimmicks. No packages</h3>
            <div className='line-textSide'></div>
            <br />



            Data is charged per kilo bytes
            (1kb). We will stick to billing per kb and show you the details
            of what and how you used your data credit. Everyone pays
            the same amount, but the more data you use, the lower the rate.

            You will have your voice account and your data account.
            No more using your voice balance for data. You can decide if you want to move credits from voice to data at your own will.

          </div>


        </div>

  
      <br />
      <br />

        <VitelFeatures/>

        <br/>

        {/* <Faq/> */}


      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
    </>

  )
}

export default DataPage