import axios from 'axios';

// const baseURLVitelWireless = 'http://10.0.5.51:3020/api/v1/';

// const baseURLVitelWireless = 'http://10.0.5.51:3020/api/v1/';

// const baseURLVitelWireless = 'http://197.210.168.18:4001/api/v1/';
//http://ipartner.vitelwireless.com/
const baseURLVitelWireless = 'https://iadmin.vitelwireless.com:4001/app4/v1/';

// const baseURLIems = "http://10.0.5.51:3013/api/v1/";

const vitelWirelessAxios = axios.create({
  baseURL: baseURLVitelWireless
});

export { vitelWirelessAxios };
