import React, { useState, useEffect } from "react";
import { vitelWirelessAxios } from "../../utility/axios";
import "./IoTDevices.css";
import noProduct from '../../images/newNoProduct.jpg'
import bracelet from "../../images/smartbracelet.png";
import watch from "../../images/wear/watch.jpg";
import camera from "../../images/wear/camera.jpg";
import vrGames from "../../images/wear/vrgames.jpg";
import { Link, useNavigate } from "react-router-dom";

const IoTDevices = () => {
  const [VitelIoTProduct, setVitelIoTProduct] = useState([]);
  const [VitelIoTProductCategory, setVitelIoTProductCategory] = useState([]);
   const [checkEmptyArr, setCheckEmptyArr] = useState();
   const [productId, setProductId] = useState('')


  const handleCategoryChange = async (e) => {
    const selectedCategory = e.target.value
     const productDetails = await vitelWirelessAxios.get(
      `/generals/getAllPublicImages`
    );

    if (selectedCategory == "all") {
      const filteredProductServOnly = productDetails.data.filter(
        (service) =>
          service.categoryName == "IoT Devices" ||
          service.categoryName === "Wearables" ||
          service.categoryName === "Mobile Phones"
      );

       const subcategoryArray = filteredProductServOnly.flatMap((item) => item.subCategoryImages);

       setCheckEmptyArr(false);
      setVitelIoTProduct(subcategoryArray);
    } else {
      const filteredServices = productDetails.data.filter(
        (service) => service.categoryId == selectedCategory
      );

      if (filteredServices.length === 0) {
        setCheckEmptyArr(true);
      } else {
        setCheckEmptyArr(false);
      }

      setVitelIoTProduct(filteredServices[0]?.subCategoryImages);
    }
  };

  const navigate = useNavigate();


  const handleNavigateDetails = ((devices)=>{
    
    // navigate(`/product-details/id=${devices.productId}/name=${devices.productName}`, { state: { product: devices } });
  
  })

  useEffect(() => {
    const getVitelIoTProduct = async () => {
      const IoTProductDetails = await vitelWirelessAxios.get(
        `/generals/getAllPublicImages`
      );
      const IoTProductCategory = await vitelWirelessAxios.get(
        `/generals/getAllCategories `
      );
      // console.log("IoTProductDetails", IoTProductDetails);

      const filteredProductServCategory = IoTProductCategory.data.filter(
        (service) =>
          service.categoryName == "IoT Devices" ||
          service.categoryName === "Wearables" ||
          service.categoryName === "Mobile Phones"
      );

      const IoTProductServOnly = IoTProductDetails.data.filter(
        (service) =>
          service.categoryName == "IoT Devices" ||
          service.categoryName === "Wearables" ||
          service.categoryName === "Mobile Phones"
      );

      const subcategoryArray = IoTProductServOnly.flatMap((item) => item.subCategoryImages);

      if (IoTProductServOnly.length === 0) {
        setCheckEmptyArr(true);
      } else {
        setCheckEmptyArr(false);
      }

      setVitelIoTProductCategory(filteredProductServCategory);
      setVitelIoTProduct(subcategoryArray);
    };
    getVitelIoTProduct();
  }, []);


  return (
    <div className='IoTDeviceParent'>
      <div className='IoTHeaderContainer'>
        <div className='IoTSecondHeaderDiv'>
          <span className='IoTHeaderText'>
            Do more with Vitel <hr />
          </span>
          <div className='IoTfilterDiv'>
            <label htmlFor='vasFilter'>filter products</label>
            <select name='vasFilter' id='' onClick={handleCategoryChange}>
              <option value='' disabled>
                Select an option
              </option>
              <option value='all'>All</option>
              {/*<option value=''>Wearables</option> */}
              {VitelIoTProductCategory &&
                VitelIoTProductCategory.map((category) => (
                  <option key={category.categoryId} value={category.categoryId}>
                    {category.categoryName}
                  </option>
                ))}
            </select>
          </div>
        </div>
      </div>
      <div className='IoTSeconParent'>
        {checkEmptyArr ? (
          <div className='IoTDeviceCard'>
            <div className='iotImageDiv'>
              <img src={noProduct} alt='' />
              <div className='iotDetailsDiv'>
                <h1>
                  No product found{" "}
                  {/* <span className='IoTPrice'> &#8358; 0</span> */}
                </h1>
                <p className='IoTDescription'>
                  No product found in this category please select another
                  category
                </p>
              </div>
            </div>
          </div>
        ) : (
          VitelIoTProduct &&
          VitelIoTProduct.map((devices) => (
            <Link to="/" className='IoTDeviceCard' onClick={()=>handleNavigateDetails(devices)}>
              <div className='iotImageDiv' >
                <img src={devices.displayImage} alt='' />
                <div className='iotDetailsDiv'>
                  <h1>
                    {devices.productName}{" "}
                    <span className='IoTPrice'>
                      {" "}
                      &#8358; {parseInt(devices.pricePerItem).toLocaleString()}
                    </span>
                  </h1>
                  <p className='IoTDescription'>{devices.shortDescription}</p>
                </div>
              </div>
            </Link>
          ))
        )}
      </div>
    </div>
  );
};

export default IoTDevices;
