import { useNavigate } from "react-router-dom";
import useFectch from "../../utility/getFunction";
import { GiShoppingCart } from "react-icons/gi";
import { FaListUl, FaSearch } from "react-icons/fa";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";



const SearchBar =(()=>{

    const [subCatName, setSubCatName] = useState('')

    const navigate = useNavigate();

	const navigateShoppingCart = (()=>{
		navigate("/shopping-cart")
	})

  const {
    data: products,
    isPending: productsIsPending,
    error: productsError
  } = useFectch('/generals/getAllPublicImages');

  const [allCategories, setAllCategories] = useState(true)

//   const {
//     data: productsCategory,
//     isPending: productsIsPendingCategory,
//     error: productsErrorCategory
//   } = useFectch('/generals/getAllCategories');

const totalCount = useSelector((state) => {

  return state.cartProduct.productItems.length

})


    // const productData = productsCategory.filter((pro)=>(
    //       pro.categoryName !== "Premium/Value Added Services" && !pro.categoryName !== "Data"
	// 	   && !pro.categoryName !== "Credit Vouchers" && !pro.categoryName !== "MSISDN Numbers" 
	// 	    && !pro.categoryName !== "SIM Cards" 
	// ))

// const filterCategories = (()=>{

// })
const subCatogoryFilter = products.subCategoryImages && products.subCategoryImages.filter((products) => (
          subCatName === products.productCategory

  ))

  const subCategoryImages = products
  .filter(product => product.subCategoryImages && product.subCategoryImages.length > 0)
  .flatMap(product => product.subCategoryImages);

  const filteredProducts = subCategoryImages.filter(product => 
    product.categoryName != "Data" && product.categoryName != "Credit Vouchers"
  );



  const [display, setDisplay] = useState(false)

  const handleDisplay = (() => {
    setDisplay(true)
  })

  const [search, setSearch] = useState("")

  const handleDirect = (() => {

    setDisplay(false)
  })
 

  const handleSearchDisplay = ((event) => {
    setSearch(event.target.value)
    if (event.target.value == 0) {
      setDisplay(false)
    } else {
      setDisplay(true)
    }

  })

  const filterCategory = products.filter(product =>
    product.categoryName != "Data" && product.categoryName != "Credit Vouchers"
  )

  // const userData = JSON.parse(localStorage.getItem("ecomUserData"));

  let locationList1 = useRef();

useEffect(() => {
    const handler = (event) => {
      if (locationList1.current && !locationList1.current.contains(event.target)) {
        locationList1.current.style.display = 'none';
      }
    };

    document.addEventListener("click", handler);

    return () => {
      document.addEventListener("click", handler);
    };
  }, [locationList1]);

  
  const handleNavigateDetails = ((product)=>{
    navigate('/e-commerce', { state: { product: product } });

  })

  const handleAllSubCat = (()=>{
    // setAllCategories(filterCategory)
    navigate('/e-commerce');
    })

  const handleSubCatName = (categories) => {
    navigate('/categories', { state: { categories: categories } });
   console.log("checksubcatname>>",categories);

  }
 


    return(


        <>
        <div className="jx-send">
        <div className="categoryDrop">
				<ul className="list_mainName-ul">
              <li className="list_mainName" >   All Categories  <span > <FaListUl className="categoryIcon"/></span>
               <li className="listS-categories" >
               <li onClick={handleAllSubCat}> <i> <FaListUl/> </i> All </li>
                   {
                  filterCategory.map((product)=>(
                  <li key={product.categoryId} 
                  onClick={() => handleSubCatName(product)}> 
            <img className="listApp-mg" src={product?.displayImage}/>  {product.categoryName}</li> 
                ))
              }
               
              </li>
              
			    
			     
			  </li>
				
				</ul>
			  
			 </div>
			<div>
			<form className="search-container">
            <input type="text" id="search-bar" placeholder="What are you looking for?" onKeyDown={(() => handleDisplay())} onChange={handleSearchDisplay}/>
              <a className="search-icon"><FaSearch size={20}/></a>

            {display && <div ref={locationList1} className="allsearch-drop">
              {
                productsIsPending && <div></div>
              }
                              {
                filteredProducts.slice(0, 10).filter((product) => (
                  product.productName.toLowerCase().indexOf(search) !== -1

                )).map ((name) => (

                  <ul className="ulcontrol-search">

                    <div  onClick={(() => handleNavigateDetails(name))} className="linktodisplay-search">
                      <li><i><img className="picSearch-control" src={name.displayImage} /> </i>
                        <span> {name.productName} </span>
                      </li>
                    </div>
                  </ul>

                ))
              }



            </div>}
           </form>
			</div>
			<div className="cart-Mycart-div">
		     <spa className="cart-Mycart-span" onClick={navigateShoppingCart}>

				<i className="cartIicon"><GiShoppingCart size={22}/>  </i> <span className="cart-Mycart">My Cart</span>
				 <span className="num-Cart"> {totalCount} </span> 
			</spa>
			</div>
          
	      </div>


    {/* /////////////////////////////////////////////////////////*  */} 

      

        
        </>
    )
})

export default SearchBar