import React from 'react'
import './SubscriberOrders.css'
import { FaMapMarkerAlt } from "react-icons/fa";
import { MdDelete } from "react-icons/md";

const SubscriberOrders = () => {
  const trackingList = [
    {
      name: "chidi joel",
      icon: <FaMapMarkerAlt />,
    },
    {
      name: "paul john",
      icon: <FaMapMarkerAlt />,
    },
    {
      name: "emma maxicov",
      icon: <FaMapMarkerAlt />,
    },
    {
      name: "Abel doe",
      icon: <FaMapMarkerAlt />,
    },
  ];

  const travelPath = [
    {
      name: "chidi joel",
      icon: <MdDelete />,
    },
    {
      name: "paul john",
      icon: <MdDelete />,
    },
    {
      name: "emma maxicov",
      icon: <MdDelete />,
    },
    {
      name: "Abel doe",
      icon: <MdDelete />,
    },
  ];

  return (
    <div className='parentTrackerList'>
      <h2 className='header-title'>Subcriber order List</h2>
      <div className='trackingList'>
        {trackingList.map((tracking) => (
          <div className='user-name'>
            <span>{tracking.name}</span>
            <span className={tracking.sos === true ? "icon1" : "icon"}>
              {tracking.icon}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SubscriberOrders;