import React, { useEffect, useState, useRef } from 'react';
import './Login.css';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../images/secondNewLogo.jpg';

const Login = () => {
  const [isOtpVisible, setIsOtpVisible] = useState(false);
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(59)
  const otpRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  const navigation = useNavigate();

  useEffect(() => {
    const container = document.getElementById('container');
    const registerBtn = document.getElementById('register');
    const loginBtn = document.getElementById('login');

    if (registerBtn && loginBtn && container) {
      const handleRegisterClick = () => container.classList.add('active');
      const handleLoginClick = () => container.classList.remove('active');

      registerBtn.addEventListener('click', handleRegisterClick);
      loginBtn.addEventListener('click', handleLoginClick);

      // Cleanup event listeners on component unmount
      return () => {
        registerBtn.removeEventListener('click', handleRegisterClick);
        loginBtn.removeEventListener('click', handleLoginClick);
      };
    }
  }, []);

  const handleSignInClick = (e) => {
    e.preventDefault();
    setIsOtpVisible(true);
  };

  const handleVerifyClick = () => {
    // Handle OTP verification logic here
    navigation('/customer/account');
  };

  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    if (!/^[0-9]$/.test(value)) {
      e.target.value = '';
      return;
    }
    if (index < otpRefs.length - 1 && value) {
      otpRefs[index + 1].current.focus();
    }
  };

  const handleOtpKeyDown = (e, index) => {
    if (e.key === 'Backspace' && index > 0 && !e.target.value) {
      otpRefs[index - 1].current.focus();
    }
  };

  const resendOTP = () => {

  }
  return (
    <div className='parentLoginContainer'>
      <div class='container' id='container'>
        <div class='form-container sign-up'>
          <form>
            <h1>Create Account</h1>
            <div class='social-icons'>
              <a href='#' class='icon'>
                <i class='fa-brands fa-google-plus-g'></i>
              </a>
              <a href='#' class='icon'>
                <i class='fa-brands fa-facebook-f'></i>
              </a>
              <a href='#' class='icon'>
                <i class='fa-brands fa-github'></i>
              </a>
              <a href='#' class='icon'>
                <i class='fa-brands fa-linkedin-in'></i>
              </a>
            </div>
            <span>or use your email for registeration</span>
            <input type='text' placeholder='Name' />
            <input type='email' placeholder='Email' />
            <input type='password' placeholder='Password' />
            <button>Sign Up</button>
          </form>
        </div>
        <div class='form-container sign-in'>
          <form>
            {!isOtpVisible ? (
              <>
                <h1>Sign In</h1>
                <div class='social-icons'>
                  <span>
                    Enter Your Vitel Number, an OTP will Be sent there
                  </span>
                </div>
              </>
            ) : (
              <>
                <h1>OTP Verification</h1>
                <div class='social-icons'>
                  <span>Code has been sent to *******67405</span>
                </div>
              </>
            )}

            {!isOtpVisible ? (
              <>
                {/* <input type='email' placeholder='Enter vitel number' /> */}
                <input type='email' placeholder='Enter vitel number' />
                <button className='signInBtn' type='button' onClick={handleSignInClick}>
                  Sign In
                </button>
              </>
            ) : (
              <>
                <div className='otp-input-card'>
                  {otpRefs.map((ref, index) => (
                    <input
                      key={index}
                      type='text'
                      maxLength='1'
                      ref={ref}
                      onChange={(e) => handleOtpChange(e, index)}
                      onKeyDown={(e) => handleOtpKeyDown(e, index)}
                      autoFocus={index === 0}
                    />
                  ))}
                </div>
                <div className='countdown-text'>
                  Time Remaining: {""}
                  <span style={{ fontWeight: 600 }}>
                    {minutes < 10 ? `0${minutes}` : minutes} {": "}
                    {seconds < 10 ? `0${seconds}` : seconds}
                  </span>
                </div>
                <span>
                  Didn't get the otp? <button className='resendOtpBtn' disabled = {seconds > 0 || minutes > 0} style={{color: seconds > 0 || minutes > 0 ? "#DFE3E8" : "#FF5630"}} onClick={resendOTP}>Resend</button>
                </span>
                <button className='verifyBtn333' type='button' onClick={handleVerifyClick}>
                  Verify2
                </button>
              </>
            )}
          </form>
        </div>
        <div class='toggle-container'>
          <div class='toggle'>
            <div class='toggle-panel toggle-left'>
              <div className='loginLogo'>
                <img src={logo} alt='' />
              </div>
              <p>Enter your personal details to use all of site features</p>
              <button class='hidden' id='login'>
                Sign In
              </button>
            </div>
            <div class='toggle-panel toggle-right'>
              <div className='loginLogo'>
                <img src={logo} alt='' />
              </div>
              <p className='toggle-panel-para'>
                Login with your Vitel Number to use all of the site features
              </p>
              <div className='buttonDiv'>
                {/* <button class='hidden' id='register'>
                Sign Up
              </button> */}
                <Link to='/'>
                  <span class='homebtn' id='register'>
                    Back To Home
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
