import React from "react";
import "./Reports.css";
import { TbReport } from "react-icons/tb";

const Reports = ({ onReportClick }) => {
  const reportList = [
    {
      name: "My SOS Report",
      icon: <TbReport />,
      reportType: "MySOS",
    },
    {
      name: "My TravelPath Report",
      icon: <TbReport />,
      reportType: "MyTravelPath",
    },
  ];

  return (
    <div className='parentReport'>
      <h2 className='header-title'>Select Report to view</h2>
      <div className='trackingList'>
        {reportList.map((tracking) => (
          <div
            key={tracking.reportType}
            className='user-name'
            onClick={() => onReportClick(tracking.reportType)}
          >
            <span>{tracking.name}</span>
            <span className='icon'>{tracking.icon}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Reports;
